<template>
  <v-form v-model="valid" ref="form">
    <v-text-field
      label="Cargo"
      name="nome"
      placeholder="Cargo"
      v-model="nome"
      :counter="30"
      :rules="nameRules"
      required
    ></v-text-field>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn outline @click="clear">Limpar</v-btn>
      <router-link :to="{ name: 'Cargo' }" class="nounderline">
        <v-btn outline>Voltar</v-btn>
      </router-link>
    </div>
  </v-form>
</template>
<script>
import { ServicoCargo } from "../../servicos/servicoCargo"
const servicoCargo = new ServicoCargo()
export default {
  data: () => ({
    id: null,
    nome: "",
    nameRules: [
      v => !!v || "Nome é obrigatório",
      v => v.length <= 30 || "Nome não deve possuir mais que 30 caracteres",
      v => v.length >= 3 || "Nome não deve possuir menos que 3 caracteres"
    ],
    valid: true
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        servicoCargo.salvarCargo(this.id, this.nome).then(
          res => {
            if (res.status === 200) {
              this.$router.push({ name: "Cargo" })
            }
          },
      // eslint-disable-next-line
          err => console.log(err)
        )
      }
    },
    clear() {
      this.nome = ""
      this.$validator.reset()
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoCargo.buscarPorId(this.id).then(res => {
        this.nome = res.data.descricao
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
